@include component(text) {
    @include part(lead) {
        @at-root {
            &, & p {
                margin-bottom: 24px;
                @include policy(margin);
                @include font-size(18px);
                font-weight: $fw-light;
                line-height: 1 + modular-scale(-2, ($font-base-height - 1) * 10) / 10;
                
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    @include part(left) {
        text-align: left;
    }

    @include part(center) {
        text-align: center;
        
        @include component(text) {
            @include part(lead) {
                text-align: center;
            }
        }
        
        @include component(img-responsive) {
            @include margin(null auto null auto);
        }
    }

    @include part(right) {
        text-align: right;
    }

    @include part(justify) {
        text-align: justify;
    }

    @include part(uppercase) {
        text-transform: uppercase;
    }

    @include part(capitalize) {
        text-transform: capitalize;
    }

    @include part(lowercase) {
        text-transform: lowercase;
    }
}

@include respond-options(text-center) {
    text-align: center;
}

@include respond-options(text-right) {
    text-align: right;
}

#{headings()} {
    margin: 24px 0 16px;
    line-height: 1.2;
    font-weight: $fw-regular;
    @include policy(margin);
    
    + {
        #{headings()} {
            margin-top: 16px;
        }
    }
    
    span.subtitle {
        color: $accent-text-color;
    }
}

@for $i from 1 through 6 {
    "h#{$i}, .h#{$i}" {
        @include font-size(map-get($heading-font-sizes, h#{$i}));
    }
}

@include component(heading-group) {
    margin: 32px 0 24px;
    @include policy(margin);
    
    #{headings()}, p {
        margin: 8px 0;
        @include policy(margin);
    }
}

p.subtitle {
    text-transform: uppercase;
    font-weight: $fw-bold;
}

p {
    margin: 12px 0;
    @include policy(margin);
}

@include component(img-responsive) {
    @include img-responsive;
    
    @include option(center) {
        @include margin(null auto null auto);
    }
}
